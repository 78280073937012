@import "@glidejs/glide/src/assets/sass/glide.core";

p.columns {
  margin-bottom: 0;

  @include breakpoint(medium) {
    margin-bottom: 1rem;
    line-height: 2.25rem;
  }
}
.text-underline {
  text-decoration: underline;
}

.card__text--paragraph {
  height: 150px;
}

// glide's relative position prevents filters from being clicked
.glide {
  position: inherit !important;
  margin-top: 0 !important;
}

.glide__bullets {
  display: flex;
  justify-content: center;
  align-items: center;
}

.glide__bullet {
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  color: lightgrey;
}

.glide__bullet:nth-of-type(2) {
  color: grey;
}

.glide__bullet:hover {
  color: grey;
}

.proposal-glance.card--header {
  height: 120px;
}

.proposal-glance__img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.proposal-glance.card__title {
  max-height: 30px;
}

.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-justify-end {
  display: flex;
  justify-content: end
}

.glide__slide {
  .card--process__small {
    height: 250px;
    
    .card__text--paragraph {
      height: 200px;
    }
  }
}