@font-face {
  font-family: 'Ultramagnetic';
  src: url('fonts/Ultramagnetic.eot');
  src: local('Ultramagnetic'),
      url('fonts/Ultramagnetic.eot?#iefix') format('embedded-opentype'),
      url('fonts/Ultramagnetic.woff2') format('woff2'),
      url('fonts/Ultramagnetic.woff') format('woff'),
      url('fonts/Ultramagnetic.ttf') format('truetype'),
      url('fonts/Ultramagnetic.svg#Ultramagnetic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
