@import "vendor/slim-select/slimselect";

.ss-main.country-select{
  margin: 0 0 $form-spacing;
}

.ss-main.country-select .ss-single-selected,
.ss-content .ss-list .ss-option{
  .country-flag{
    margin-right: .5rem;

    img{
      position: relative;
      display: inline-block;
      width: 1rem;
    }
  }
}

.ss-main.country-select{
  .ss-single-selected{
    padding: $input-padding;
    font-size: $input-font-size;
    line-height: $input-line-height;
    height: 2.4375rem;
    border: $input-border;
    box-shadow: $input-shadow;

    .placeholder{
      white-space: unset;

      .country-flag{
        flex: 0 0 16px;

        img{
          width: 100%;
        }
      }
    }

    .country-text{
      white-space: nowrap;
    }
  }
}

.half-signup-methods{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;

  @include breakpoint(smallmedium up){
    flex-direction: row;
  }

  .half-signup-methods-item{
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin: 0 1rem;
    font-size: 1.6rem;
    text-align: center;
    align-self: center;

    &.half-signup-methods-item-or{
      margin: 1.5rem 0;
    }

    svg{
      width: 5rem;
      fill: currentColor;

      @include breakpoint(smallmedium up){
        width: 6rem;
      }
    }

    .button-wrapper{
      @include breakpoint(smallmedium up){
        margin-top: 1rem;
      }
    }

    .button{
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.flex-digits{
  display: flex;
  justify-content: space-between;

  .flex-digits-input{
    display: flex;
    flex: 0 0 auto;

    input[type=number] {
      -moz-appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }

    input{
      margin: 0 auto;
      height: 2.5rem;
      width: 2.5rem;
      text-align: center;

      @include breakpoint(smallmedium up){
        height: 3rem;
        width: 3rem;
      }
    }
  }
}

